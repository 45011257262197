import React from 'react';
import ViewMarianTasks from './view_marian_tasks'
import AddTask from './add_task'
import Login from './login'
import ViewInstallationsAsKrystian from './view_installation_as_krystian'
import ViewInstallationsAsAdmin from './view_installation_as_admin'
import ViewInstallationsAsSales from './view_installation_as_sales'
import ViewInstallationsAsMontage from './view_installation_as_montage'
import ViewAirConditioningAsAdmin from './view_air_conditioning_as_admin'
import ViewAirConditioningAsMontage from './view_air_conditioning_as_montage'
import ViewWarehouse from './view_warehouse'
import ViewHeatAsAdmin from './view_heat_as_admin';
import AddPumpHeat from './add_heat_pump';
import { withRouter } from 'react-router-dom'
import AddAll from './add_all';
import AddInstallation from './add_installation'
import AddAirConditioning from './add_air_conditioning'
import AddOfficeTask from './add_office_task';
import ViewOfficeTasks from './view_office_tasks';
import { Container, Nav, Navbar } from 'react-bootstrap';
import Cookies from "js-cookie";
import { Form, Button, Row, Col } from 'react-bootstrap';
import './App.css'

import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";

export function Main() {
  const MyRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
  return (
    <>
      <Router>

        <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
          <Container fluid>
            <Navbar.Brand>
              <img
                alt=""
                src="https://static.wixstatic.com/media/de8b96_1dd12fda107345d1997e5d5688675002~mv2.png/v1/fill/w_183,h_116,al_c,q_85,usm_0.66_1.00_0.01/logo%20bia%C5%82e.webp"
                width="50"
                height="30"
                className="d-inline-block align-top"
              />{' '}
              </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Item><Nav.Link className='border-right' href="/#/dodaj">{ (Cookies.get("Rights") === "admin" || Cookies.get("Rights") === "handel" || Cookies.get("Rights") === "superadmin" || Cookies.get("Rights") === "regular")  ? "Dodaj instalacje" : ""}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link className='border-right' href="/#/instalacje/administracja">Administracja fotowoltaika</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link className='border-right' href="/#/instalacje/handlowcy">Handlowcy fotowoltaika</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link className='border-right' href="/#/instalacje/monterzy">Monterzy fotowoltaika</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link className='border-right' href="/#/klimatyzacje/administracja">{ (Cookies.get("Rights") === "superadmin" || Cookies.get("Rights") === "regular") ? "Administracja klimatyzacje" : ""}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link className='border-right' href="/#/klimatyzacje/monterzy">Monterzy klimatyzacje</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link className='border-right' href="/#/zadania/przegladaj">Zadania</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link href="/#/zadania_biurowe/przegladaj">Zadania biuro</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link className='border-right' href="/hyperion_energy.db">{(Cookies.get("Rights") === "admin" || Cookies.get("Rights") === "superadmin") ? "Kopia bazy" : ""}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link className='border-right' href="/#/pompy/administracja">Pompy ciepła</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link className='border-right' href="/#/zaloguj">{(Cookies.get("Nick") == null) ? 'Zaloguj' : Cookies.get("Nick") + ' - (zaloguj)'}</Nav.Link></Nav.Item>
                {
                  Cookies.get("Rights") === "superadmin"?<Nav.Item><Nav.Link className='border-right' href="/#/magazyn">Magazyn</Nav.Link></Nav.Item>:null
                }
                <Nav.Item><Nav.Link href="/#/instalacje/krystian">_</Nav.Link></Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Switch>
          <MyRoute path="/dodaj" component={AddAll} />
          <MyRoute path="/instalacje/dodaj" component={AddInstallation} />
          <MyRoute path="/instalacje/administracja" component={ViewInstallationsAsAdmin} />
          <MyRoute path="/instalacje/krystian" component={ViewInstallationsAsKrystian} />
          <MyRoute path="/instalacje/handlowcy" component={ViewInstallationsAsSales} />
          <MyRoute path="/instalacje/monterzy" component={ViewInstallationsAsMontage} />
          <MyRoute path="/klimatyzacje/dodaj" component={AddAirConditioning} />
          <MyRoute path="/klimatyzacje/administracja" component={ViewAirConditioningAsAdmin} />
          <MyRoute path="/klimatyzacje/monterzy" component={ViewAirConditioningAsMontage} />
          <MyRoute path="/pompy/administracja" component={ViewHeatAsAdmin} />
          <MyRoute path="/pompy/dodaj" component={AddPumpHeat} />
          <MyRoute path="/zadania/dodaj" component={AddTask} />
          <MyRoute path="/zadania/przegladaj" component={ViewMarianTasks} />
          <MyRoute path="/zadania_biurowe/dodaj" component={AddOfficeTask} />
          <MyRoute path="/zadania_biurowe/przegladaj" component={ViewOfficeTasks} />
          <MyRoute path="/zaloguj" component={Login} />
          <MyRoute path="/magazyn" component={ViewWarehouse} />
          <MyRoute path="/" component={Login} />
        </Switch>
      </Router>
    </>
  );
}

export default withRouter(Main);
