import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import CurrencyInput from 'react-currency-input-field';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
export default function SingleProduct({ element, destinations, refresh, soldItems, orderedItemsQuantities, array, index, last, moveup, tab, movedown }) {
    const [Name, setName] = useState(element.name);
    const [Destination, setDestination] = useState(element.destination);
    const [PurchasePrice, setPurchasePrice] = useState(element.purchase_price);
    const [Quantity, setQuantity] = useState(element.quantity);
    const [SellingPrice, setSellingPrice] = useState(element.selling_price);
    const [EditDisable, setEditDisable] = useState(false);
    const [RemoveDisabe, setRemoveDisabe] = useState(false);
    const [Move, setMove] = useState(false);
    useEffect(() => {
        if (Name === element.name && Destination === element.destination && PurchasePrice === element.purchase_price && Quantity === element.quantity && SellingPrice === element.selling_price) {
            setEditDisable(true)
        } else {
            setEditDisable(false)
        }
    }, [Name, Destination, PurchasePrice, Quantity, SellingPrice]);


    //const timer = () => setTimeout(() => setMove(false) , 3000);
    const [timer, settimer] = useState(false);
    const goUp = (current, prew, tabName) => {
        moveup(current, prew, tabName)
        setMove(true)

        clearTimeout(timer);
        settimer(setTimeout(function () {
            setMove(false)
        }, 1000));
    }
    const goDown = (current, next, tabName) => {
        movedown(current, next, tabName)
        setMove(true)
        clearTimeout(timer);
        settimer(setTimeout(function () {
            setMove(false)
        }, 1000));
    }
    const [HowManySoldItems, setHowManySoldItems] = useState(0);
    const [OrderedItemsQuantities, setOrderedItemsQuantities] = useState(0);
    useEffect(() => {
        Object.keys(soldItems).map(e => {
            if (parseInt(soldItems[e].equipement_id) == parseInt(element.id)) {
                setHowManySoldItems(soldItems[e].sold_quantity)
            }
        })
    }, [element, soldItems]);

    useEffect(() => {

        Object.keys(orderedItemsQuantities).map(e => {
            if (parseInt(orderedItemsQuantities[e].equipement_id) == parseInt(element.id)) {
                setOrderedItemsQuantities(orderedItemsQuantities[e].ordered_quantity)
            }
        })
    }, [element, orderedItemsQuantities]);

    const edit = async () => {
        let json = {
            name: Name,
            destination: Destination,
            quantity: parseInt(Quantity),
            purchase_price: parseFloat(PurchasePrice.replace(/,/g, '.')),
            selling_price: parseFloat(SellingPrice.replace(/,/g, '.'))
        }
        try {
            let response = await axios.put(`https://fotodemo.srv22208.microhost.com.pl/warehouse/warehouse_items.php?id=${element.id}`,
                json,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            refresh()
            console.log(response)
            setEditDisable(true)
        } catch (error) {
            console.log(error)
            setEditDisable(true)
        }
    }
    const remove = async () => {
        setRemoveDisabe(true)
        try {
            let response = await axios.delete(`https://fotodemo.srv22208.microhost.com.pl/warehouse/warehouse_items.php?id=${element.id}`)
            console.log(response)
            refresh()
            setRemoveDisabe(false)
        } catch (error) {
            console.log(error)
            setRemoveDisabe(false)
        }
    }
    return (
        <Row className={Move ? `border py-3 mb-2 px-1 mx-0 bg-info` : `border py-3 mb-2 px-1 mx-0 `}>
            <Col xs={3}>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Nazwa</label>
                    <input type="text" class="form-control" value={Name} onChange={(e) => setName(e.target.value)} id="exampleFormControlInput1" />
                </div>
            </Col>
            <Col xs={2}>
                <div class="form-group">
                    <label for="exampleFormControlSelect1">Przypisane do</label>
                    <select class="form-control" id="exampleFormControlSelect1" onChange={(e) => setDestination(e.target.value)}>
                        {
                            destinations.map(element => {
                                return <option key={element.destination} selected={element.destination === Destination} value={element.destination}>{element.name}</option>
                            })
                        }
                    </select>
                </div>
            </Col>
            {/*}            <Col xs={1}>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Cena zakupu</label>
                    <CurrencyInput
                        id="input-example"
                        name="input-name"
                        class="form-control"
                        decimalsLimit={2}
                        decimalScale={2}
                        value={PurchasePrice}
                        onValueChange={(value) => setPurchasePrice(value)}
                    //onValueChange={(value) => console.log(parseFloat(value.replace(/,/g, '.')))}
                    />
                </div>
            </Col>*/}
            <Col xs={2}>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Ilość towaru na stanie</label>
                    <input type="number" class="form-control" value={Quantity} onChange={(e) => setQuantity(e.target.value)} id="exampleFormControlInput1" />
                </div>
            </Col>
            {/*}            <Col xs={1}>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Cena sprzedaży</label>
                    <CurrencyInput
                        id="input-example"
                        name="input-name"
                        class="form-control"
                        decimalsLimit={2}
                        decimalScale={2}
                        value={SellingPrice}
                        onValueChange={(value) => setSellingPrice(value)}
                    />
                </div>
            </Col>*/}

            <Col xs={1}>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Do montażu</label>
                    <input type="text" disabled class="form-control" value={OrderedItemsQuantities} id="exampleFormControlInput1" />
                </div>
            </Col>
            {/* <Col xs={1}>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Sprzedane</label>
                    <input type="text" disabled class="form-control" value={HowManySoldItems} id="exampleFormControlInput1" />
                </div>
            </Col>*/}

            <Col xs={2} className="d-flex align-items-center ">
                <div className='px-3'>
                    <Button onClick={() => edit()} variant='success' className='mr-5' disabled={EditDisable}>
                        Zapisz zmiany
                    </Button>
                </div>
                <div className='px-3'>
                    <Button onClick={() => remove()} variant='danger' className='ml-5' disabled={RemoveDisabe}>
                        Usuń
                    </Button>
                </div>
            </Col>
            <Col xs={2} className="d-flex align-items-center ">
                <Row className="mx-0">
                    <Col xs={12} className="d-flex align-items-center ">

                        <Button onClick={() => goUp(index, index - 1, tab)} variant="link" className='mr-5 upDown' disabled={index === 0 ? true : false}>
                            <FaAngleUp />
                        </Button>

                    </Col>
                    <Col xs={12} className="d-flex align-items-center ">

                        <Button onClick={() => goDown(index, index + 1, tab)} variant="link" className='ml-5 upDown' disabled={last}>
                            <FaAngleDown />
                        </Button>

                    </Col>
                </Row>
            </Col>
            <style>
                {
                    `
              
               #Test${element.id}{
                  background: rgba(255,255,255,0.5);
   
   
     -webkit-transition: background 5.9s ease-out;
     -moz-transition: background 5.9s ease-out;
     -o-transition: background 5.9s ease-out;
     transition: background 5.9s ease-out;
                            }
                 #Test${element.id}:active {
     background: rgba(255,0,0,1);
                     }
              
              
               `
                }
            </style>
        </Row>
    );
}
