//https://feedweb.pl/hyperion/db/get_data_for_branches.php?which=sales

import { Form, Button, Row, Col, Badge, DropdownButton, Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router-dom'
import axios from "axios";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import FileBase64 from "react-file-base64";

export function ViewInstallationsAsSales() {

    const [data, setData] = useState([{}]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [allYears, setAllYears] = useState([]);

    const getData = async () => {
        try {
            if (Cookies.get("Rights") === "admin" || Cookies.get("Rights") === "handel" || Cookies.get("Rights") === "superadmin" || Cookies.get("Rights") === "regular") {
                const response = await axios.get(`https://fotodemo.srv22208.microhost.com.pl/get_data_for_branches.php?which=sales&year=${selectedYear}`);
                const years = await axios.get(`https://fotodemo.srv22208.microhost.com.pl/get_years.php`);
                setAllYears(years.data)
                setData([{}]);
                setData(response.data);
            }
        } catch (e) {
            console.log(e);
        }}

    useEffect(() => {
         getData();
    }, [])

    const toggleYear = async (currentYear) => {
        let tempYear = currentYear;
        setSelectedYear(currentYear)

        if (Cookies.get("Rights") === "admin" || Cookies.get("Rights") === "handel" || Cookies.get("Rights") === "superadmin" || Cookies.get("Rights") === "regular") {
                await axios.get(`https://fotodemo.srv22208.microhost.com.pl/get_data_for_branches.php?which=sales&year=${tempYear}`).then((e) => {
                    setData([])
                    setData(e.data);
                });
        }
    }


    var fill_texts_dict = {
        "NAME": { "label": "Nazwisko i imie", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        "PHONE_NUMBER": { "label": "Numer telefonu", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        "INSTALLATION_ADDRESS": { "label": "Adres", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        "PRICE": { "label": "Cena", "placeholder": "", "error_text": "", "data_type": "REAL", "value": "" },
        "INSTALLATION_POWER": { "label": "Moc instalacji", "placeholder": "", "error_text": "", "data_type": "REAL", "value": "" },
        "LAST_CONTACT": { "label": "Ostatni kontakt", "placeholder": "", "error_text": "", "data_type": "DATE", "value": "" },
        "WHEN_CONTACT": { "label": "Kiedy się skontaktować", "placeholder": "", "error_text": "", "data_type": "DATE", "value": "" },
        "SIGN_DATE": { "label": "Data podpisania", "placeholder": "", "error_text": "", "data_type": "DATE", "value": "" },
        "OFFER_TYPE": { "label": "Typ oferty", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        "WHOS_CLIENT": { "label": "Czyj klient", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        "INFORMATIONS_FOR_SALES": { "label": "Dodatkowe informacje", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
    };
    var select_elements_dict = {
        "ROOF_TYPE": { "label": "Typ dachu", "placeholder": "", "error_text": "", "data_type": "TEXT", "elements": [" ", "Dachówka", "Blachodachówka", "Trapez", "Rąbek", "Płaski Grunt"], "value": "" },
        "POTENTIAL_CLIENT": { "label": "Klient", "placeholder": "", "error_text": "", "data_type": "TEXT", "elements": [" ", "Potencjalny", "Pewny"], "value": "" },
    };

    function handleSaveEdited(installation_index) {
        let installation = data[installation_index]
        let installation_id = data[installation_index].ID
        if (installation.hasOwnProperty('ID'))
            delete installation['ID']
        axios.post(`https://fotodemo.srv22208.microhost.com.pl/edit_installation.php?id=` + installation_id,
            installation,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then(res => {
            getData()
        })
    }

    function handleDelete(installation_index) {
        let installation_id = data[installation_index].ID
        axios.get(`https://fotodemo.srv22208.microhost.com.pl/delete_installation.php?id=` + installation_id,).then(res => {
            getData()
        })
    }

    function attachments(installation_index) {

        const File1 = (files) => {
            axios
                .post(
                    `https://fotodemo.srv22208.microhost.com.pl/save_attachment.php`,
                    {
                        file: files.base64,
                        filename: files.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g,"_"),
                        installation: data[installation_index].ID,
                        attachment_nr: 1
                    },
                    {
                        "Content-Type": "application/json",
                    }
                )
                .then((response) => {
                    getData()
                })
        };
        const File2 = (files) => {
            axios
                .post(
                    `https://fotodemo.srv22208.microhost.com.pl/save_attachment.php`,
                    {
                        file: files.base64,
                        filename: files.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g,"_"),
                        installation: data[installation_index].ID,
                        attachment_nr: 2
                    },
                    {
                        "Content-Type": "application/json",
                    }
                )
                .then((response) => {
                    getData()
                })
        };
        const File3 = (files) => {
            axios
                .post(
                    `https://fotodemo.srv22208.microhost.com.pl/save_attachment.php`,
                    {
                        file: files.base64,
                        filename: files.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g,"_"),
                        installation: data[installation_index].ID,
                        attachment_nr: 3
                    },
                    {
                        "Content-Type": "application/json",
                    }
                )
                .then((response) => {
                    getData()
                })
        };

        function handleDownloadAttachment(installation_index, which_attachment) {
            if (which_attachment === 1)
                if (data[installation_index].ATTACHMENTS !== undefined) {
                    if (data[installation_index].ATTACHMENTS !== '') {
                        window.open('https://fotodemo.srv22208.microhost.com.pl/' + data[installation_index].ATTACHMENTS, "_blank");
                        return 0;
                    }
                }
            if (which_attachment === 2)
                if (data[installation_index].ATTACHMENT2 !== undefined) {
                    if (data[installation_index].ATTACHMENT2 !== '') {
                        window.open('https://fotodemo.srv22208.microhost.com.pl/' + data[installation_index].ATTACHMENT2, "_blank");
                        return 0;
                    }
                }
            if (which_attachment === 3)
                if (data[installation_index].ATTACHMENT3 !== undefined) {
                    if (data[installation_index].ATTACHMENT3 !== '') {
                        window.open('https://fotodemo.srv22208.microhost.com.pl/' + data[installation_index].ATTACHMENT3, "_blank");
                        return 0;
                    }
                }
        }

        function handleDeleteAttachment(installation_index, which_attachment) {
            if (which_attachment === 1)
                axios
                    .post(
                        `https://fotodemo.srv22208.microhost.com.pl/delete_attachment.php`,
                        {
                            filename: data[installation_index].ATTACHMENTS,
                            installation: data[installation_index].ID,
                            attachment_nr: which_attachment
                        },
                        {
                            "Content-Type": "application/json",
                        }
                    )
                    .then((response) => {
                        getData()
                    })
            if (which_attachment === 2)
                axios
                    .post(
                        `https://fotodemo.srv22208.microhost.com.pl/delete_attachment.php`,
                        {
                            filename: data[installation_index].ATTACHMENT2,
                            installation: data[installation_index].ID,
                            attachment_nr: which_attachment
                        },
                        {
                            "Content-Type": "application/json",
                        }
                    )
                    .then((response) => {
                        getData()
                    })
            if (which_attachment === 3)
                axios
                    .post(
                        `https://fotodemo.srv22208.microhost.com.pl/delete_attachment.php`,
                        {
                            filename: data[installation_index].ATTACHMENT3,
                            installation: data[installation_index].ID,
                            attachment_nr: which_attachment
                        },
                        {
                            "Content-Type": "application/json",
                        }
                    )
                    .then((response) => {
                        getData()
                    })
        }

        let att1 = 'Załącznik 1'
        let att1bool = false
        let att2 = 'Załącznik 2'
        let att2bool = false
        let att3 = 'Załącznik 3'
        let att3bool = false
        if (data[installation_index].ATTACHMENTS !== undefined) {
            if (data[installation_index].ATTACHMENTS !== '' && data[installation_index].ATTACHMENTS !== null) {
                att1 = data[installation_index].ATTACHMENTS.substring(14, 32)
                att1bool = true
            }
        }
        if (data[installation_index].ATTACHMENT2 !== undefined) {
            if (data[installation_index].ATTACHMENT2 !== '' && data[installation_index].ATTACHMENT2 !== null) {
                att2 = data[installation_index].ATTACHMENT2.substring(14, 32)
                att2bool = true
            }
        }
        if (data[installation_index].ATTACHMENT3 !== undefined) {
            if (data[installation_index].ATTACHMENT3 !== '' && data[installation_index].ATTACHMENT3 !== null) {
                att3 = data[installation_index].ATTACHMENT3.substring(14, 32)
                att3bool = true
            }
        }
        return (
            <>
                <Col className="m-0 col-12 col-md-4 col-lg-2">
                    {att1bool ? <div><Button className='ml-2' variant="secondary" size='sm' onClick={() => handleDownloadAttachment(installation_index, 1)}>{att1}</Button> <Button className='ml-1 mr-3' variant="dark" size='sm' onClick={() => handleDeleteAttachment(installation_index, 1)}>X</Button></div> :
                        <FileBase64 multiple={false} onDone={File1.bind()} />}
                    
                </Col>
                <Col className="m-0 col-12 col-md-4 col-lg-2">
                    {att2bool ? <div><Button className='mx-0' variant="secondary" size='sm' onClick={() => handleDownloadAttachment(installation_index, 2)}>{att2}</Button> <Button className='ml-1 mr-3' variant="dark" size='sm' onClick={() => handleDeleteAttachment(installation_index, 2)}>X</Button></div> :
                        <FileBase64 multiple={false} onDone={File2.bind()} />}
                </Col>
                <Col className="m-0 col-12 col-md-4 col-lg-2">
                    {att3bool ? <div><Button className='mx-0' variant="secondary" size='sm' onClick={() => handleDownloadAttachment(installation_index, 3)}>{att3}</Button> <Button className='ml-1 mr-3' variant="dark" size='sm' onClick={() => handleDeleteAttachment(installation_index, 3)}>X</Button></div> :
                        <FileBase64 multiple={false} onDone={File3.bind()} />}
                    
                </Col>
            </>
        );
    }

    function FillTextElement(element_key, installation_index) {
        let control_by_type = <Form.Control className="m-0" as="textarea" rows={1} type="text" defaultValue={data[installation_index][element_key]} placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { data[installation_index][element_key] = e.target.value; }} />
        if (fill_texts_dict[element_key]["data_type"] === "REAL") {
            if ((element_key === "PRICE" ||  element_key === "ADVANCE") && Cookies.get("Rights") === 'regular'){
                control_by_type = <Form.Control className="m-0" disabled type='number' step="0.1" min='0' max='999999999' defaultValue={data[installation_index][element_key]} placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { data[installation_index][element_key] = e.target.value; }} />
            }
            else{
                control_by_type = <Form.Control className="m-0" type='number' step="0.1" min='0' max='999999999' defaultValue={data[installation_index][element_key]} placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { data[installation_index][element_key] = e.target.value; }} />
            }
        }
        if (fill_texts_dict[element_key]["data_type"] === "DATE") {
            control_by_type = <Form.Control className="m-0" type='date' defaultValue={data[installation_index][element_key]} placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { data[installation_index][element_key] = e.target.value; }} />
        }

        return (
            <>
                <Col className="m-0 col-6 col-md-3 col-lg-2">
                    <Form.Group className="m-0" controlId={element_key}>
                        <Form.Label className="m-0" size="sm">{fill_texts_dict[element_key]["label"]}</Form.Label>
                        {control_by_type}
                    </Form.Group>
                </Col>
            </>
        );
    }

    function SelectElement(element_key, installation_index) {
        return (
            <>
                <Col className="m-0 col-4 col-md-2 col-lg-1">
                    <Form.Group className="m-0" controlId={element_key}>
                        <Form.Label className="m-0" size="sm">{select_elements_dict[element_key]["label"]}</Form.Label>
                        <Form.Control className="m-0" as="select"
                            onChange={(e) => {
                                let d = data;
                                d[installation_index][element_key] = e.target.value;
                                setData(d);
                            }}
                        >
                            {
                                select_elements_dict[element_key]["elements"].map((element) => {
                                    return <option selected={element === data[installation_index][element_key] ? true : false}>{element}</option>
                                }
                                )
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>
            </>
        );
    }

    let date = '0000-00'
    let id_in_month = 0
    function columnedView(installation_index) {
        let elements = []
        for (var key in fill_texts_dict) {
            if (fill_texts_dict.hasOwnProperty(key)) {
                elements.push(FillTextElement(key, installation_index))
            }
        }

        for (var key in select_elements_dict) {
            if (select_elements_dict.hasOwnProperty(key)) {
                elements.push(SelectElement(key, installation_index))
            }
        }
        let elements0_5 = []
        let elements6_11 = []
        for (let step = 0; step < elements.length; step++) {
            if (step < 6)
                elements0_5.push(elements[step])
            else
                elements6_11.push(elements[step])
        }
        function color_by_status() {
            /*if (data[installation_index].FINISHED === "Tak")
                return { 'background-color': '#808080', border: '2px solid #000' }
            else if (data[installation_index].ADVANCE >= data[installation_index].PRICE && data[installation_index].MOUNTED === "Tak")
                return { 'background-color': '#42f57e', border: '2px solid #000' }
            else if (data[installation_index].ADVANCE >= data[installation_index].PRICE && data[installation_index].MOUNTED !== "Tak")
                return { 'background-color': '#f5c33b', border: '2px solid #000' }
            else if (data[installation_index].ADVANCE <= data[installation_index].PRICE && data[installation_index].MOUNTED === "Tak")
                return { 'background-color': '#32a4a8', border: '2px solid #000' }*/
            return { 'backgroundColor': '#fff', border: '2px solid #000' }
        }

        function idInMonth() {
            return ++id_in_month
        }

        function date_plate() {
            function calculate_number_of_installations(when_contact_month, index) {
                let number_of_installation_in_month = 0
                let current_contact_month = when_contact_month
                for (let i = index; i < data.length; i++) {
                    if (current_contact_month === when_contact_month) {
                        if ((i + 1) < data.length)
                            if (current_contact_month === 'jutro' || current_contact_month === 'dziś')
                                current_contact_month = 'nieokreślono'
                            else
                                current_contact_month = data[i + 1].WHEN_CONTACT.substring(0, 7)
                        else
                            current_contact_month = '0000-00'
                        if (data[i].FINISHED !== "Tak" && data[i].POTENTIAL_CLIENT !== 'Pewny')
                            number_of_installation_in_month++
                    }
                    else
                        break
                }
                return number_of_installation_in_month
            }
            if (data[installation_index].WHEN_CONTACT !== undefined) {
                let when_contact = data[installation_index].WHEN_CONTACT.substring(0, 7)
                if (date !== when_contact) {
                    if (when_contact === 'jutro' || when_contact === 'dziś')
                        when_contact = 'nieokreślono'
                    date = when_contact
                    id_in_month = 0
                    let num = calculate_number_of_installations(when_contact, installation_index)
                    return <h2><Badge variant="success">{when_contact}</Badge> <Badge variant="success">Ilość w miesiącu {num}</Badge></h2>
                }
            }
            return <div></div>
        }
        return (
            <>
                {date_plate()}
                <div style={color_by_status()}>
                    <div style={{ margin: '-2px 2px 0px 2px' }}>
                        <Form className="m-0">
                            <Row>
                                <Col className="m-0">
                                    <h3>
                                        <Badge className="mt-0" variant="info">{idInMonth()}</Badge> <Badge className="mt-0" variant="info">{data[installation_index].INSTALLATION_ADDRESS} -- {data[installation_index].NAME}</Badge>
                                        <Button className='mx-1' variant="primary" size='sm' onClick={() => handleSaveEdited(installation_index)}>Zapisz</Button>
                                        {Cookies.get("Rights") !== 'regular' ? 
                                        <Button className='mr-3' variant="danger" size='sm' onClick={() => { if (window.confirm('Czy na pewno chcesz usunąć instalacje ' + (installation_index + 1) + ' ?')) handleDelete(installation_index)}}>Usuń</Button>
                                        :
                                        <></>
                                        }
                                    </h3>
                                </Col>

                                {attachments(installation_index)}

                            </Row>

                            <Row className="m-0">
                                {elements.map((elem) => (
                                    elem
                                ))}
                            </Row>

                        </Form>
                    </div>
                </div>
            </>
        );
    }
    let not_shure_clients = 0;
    function installations() {
        let installations = []
        for (let i = 0; i < data.length; i++) {
            if (data[i].FINISHED !== "Tak" && data[i].POTENTIAL_CLIENT !== 'Pewny') {
                not_shure_clients++
                installations.push(columnedView(i))
            }
        }
        return installations
    }
    let installations_arr = []
    if (Cookies.get("Rights") === "admin" || Cookies.get("Rights") === "handel" || Cookies.get("Rights") === "superadmin" || Cookies.get("Rights") === "regular") {
        installations_arr = installations()
    }
    
    return (
        <>
            <link
                rel="stylesheet"
                href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
                integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
                crossorigin="anonymous"
            />

            <div className='d-flex mx-2 flex-row'>
            <h2><Badge variant="success">Ilość nie pewnych klientów {not_shure_clients}</Badge></h2>
                                <DropdownButton className="ml-5 mt-1" id="dropdown-basic-button" title={selectedYear}>
                                    {
                                        allYears?.map((element) => {
                                            return (
                                                <Dropdown.Item style={{minWidth: 'unset'}} key={`yearId_${element}`} onClick={() => {toggleYear(element)}}>{element}</Dropdown.Item>
                                            )
                                        })
                                    }
                                </DropdownButton>
            </div>

            <div>
                
                {installations_arr.map((elem) => (
                    elem
                ))}
            </div>
        </>
    );
}

export default withRouter(ViewInstallationsAsSales);
