import { Form, Button, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import React, { useState } from "react";
import axios from "axios";
import WarehouseAddModal from './components/warehouse_add_modal';

export function AddPumpHeat() {
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substr(0, 10);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [addedItems, setAddedItems] = useState([]);
  const [fill_texts_dict, setFillTextsDict] = useState({
    "NAME": { "label": "Nazwisko i imie", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
    "PHONE_NUMBER": { "label": "Numer telefonu", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
    "ADDRESS": { "label": "Adres", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
    "PRICE": { "label": "Cena", "placeholder": "", "error_text": "", "data_type": "REAL", "value": 0 },
    "ADVANCE": { "label": "Zaliczka", "placeholder": "", "error_text": "", "data_type": "REAL", "value": 0 },
    "PUMP_MODEL": { "label": "Nazwa pompy", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
    "PUMP_POWER": { "label": "Moc pompy", "placeholder": "", "error_text": "", "data_type": "REAL", "value": 0 },
    "BUFOR_CAPACITY": { "label": "Pojemność buforu", "placeholder": "", "error_text": "", "data_type": "REAL", "value": 0 },
    "CW_CAPACITY": { "label": "Pojemność CW", "placeholder": "", "error_text": "", "data_type": "REAL", "value": 0 },
    "MONTAGE_INFO": { "label": "Info dla montażu", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
    "MOUNTAGE_DATE": { "label": "Data montażu", "placeholder": "", "error_text": "", "data_type": "DATE", "value": "2021-01-01" },
    "ADDED_DATE": { "label": "Data dodania", "placeholder": "", "error_text": "", "data_type": "DATE", "value": date },
  })
  const [select_elements_dict, setSelectElementsDict] = useState({
    "HEATING_TYPE": { "label": "Typ ogrzewania", "placeholder": "", "error_text": "", "data_type": "TEXT", "elements": ["Podłogówka", "Grzejniki", "Oba"], "value": "" },
    "MATERIALS_AVALIABLE": { "label": "Materiały na stanie", "placeholder": "", "error_text": "", "data_type": "TEXT", "elements": ["Tak", "Nie"], "value": "" },
  })

  let handleSubmit = event => {
    event.preventDefault();
    let json = {}
    for (var key in fill_texts_dict) {
      if (fill_texts_dict.hasOwnProperty(key)) {
        json[key] = fill_texts_dict[key]["value"]
      }
    }
    for (var key in select_elements_dict) {
      if (select_elements_dict.hasOwnProperty(key)) {
        json[key] = select_elements_dict[key]["value"]
      }
    }
    AddToDatabase()
  }

  function AddToDatabase() {
    let json = {}
    for (var key in fill_texts_dict) {
      if (fill_texts_dict.hasOwnProperty(key)) {
        json[key] = fill_texts_dict[key]["value"]
      }
    }
    for (var key in select_elements_dict) {
      if (select_elements_dict.hasOwnProperty(key)) {
        json[key] = select_elements_dict[key]["value"]
      }
    }
    json["WAREHOUSE_ITEMS"] = addedItems;
    axios.post(`https://fotodemo.srv22208.microhost.com.pl/add_heat_pump.php`,
      json,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
      .then(res => {
        window.location.reload(false);
        setAddedItems([]);
      })
  }

  function FillTextElement(element_key) {
    let control_by_type = <Form.Control type="text" placeholder={fill_texts_dict[element_key]["placeholder"]} defaultValue=' ' onChange={(e) => { fill_texts_dict[element_key]["value"] = e.target.value; }} />
    if (fill_texts_dict[element_key]["data_type"] === "REAL") {
      control_by_type = <Form.Control type='number' step="0.1" min='0' max='999999999' defaultValue='0' placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { fill_texts_dict[element_key]["value"] = e.target.value; }} />
    }
    else if (fill_texts_dict[element_key]["data_type"] === "DATE") {
      control_by_type = <Form.Control type='date' placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { fill_texts_dict[element_key]["value"] = e.target.value; }} />
    }

    return (
      <>
          <Form.Group controlId={element_key}>
            <Form.Label>{fill_texts_dict[element_key]["label"]}</Form.Label>
            {control_by_type}
            <Form.Text className="text-muted">{fill_texts_dict[element_key]["error_text"]}</Form.Text>
          </Form.Group>
      </>
    );
  }

  function SelectElement(element_key) {
    return (
      <>
          <Form.Group controlId={element_key}>
            <Form.Label>{select_elements_dict[element_key]["label"]}</Form.Label>
            <Form.Control as="select"
              onChange={(e) => {
                select_elements_dict[element_key]["value"] = e.target.value;
              }}
            >
              {
                select_elements_dict[element_key]["elements"].map((element) => {
                  return <option selected={true}>{element}</option>
                }
                )
              }
            </Form.Control>
          </Form.Group>
      </>
    );
  }

  let elements = []
  for (var key in fill_texts_dict) {
    if (fill_texts_dict.hasOwnProperty(key)) {
      elements.push(FillTextElement(key))
    }
  }

  for (var key in select_elements_dict) {
    if (select_elements_dict.hasOwnProperty(key)) {
      elements.push(SelectElement(key))
    }
  }

  const handleOpenModal = async () => {
    if(modalData.length > 1){
      console.log(modalData)
      setOpenModal(true)
    } else {
      const response = await axios.get(`https://fotodemo.srv22208.microhost.com.pl/warehouse/warehouse_items.php`);
      setModalData(response.data.heat_pumps.items);
      setOpenModal(true)
    }
  }

  return (
    <>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
        integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
        crossorigin="anonymous"
      />
      <div className="px-md-5 pt-md-4">
          <Form>
            <Row>
            {elements.map((elem) => (
                   <Col className="m-0 col-12 col-md-6 col-lg-4"> {elem} </Col>
                ))}
              </Row>

              <Button className='my-4' variant="success" onClick={() => handleOpenModal()}>Magazyn</Button>
              <Row>
              {addedItems.map((item) => (
                <div className="d-flex flex-row">
                  <p className="mr-5" key={`added_item_${item.id}`}><strong>Nazwa części:</strong> {item.name}</p>
                  <p key={`added_item_quant_${item.id}`}><strong>Ilość:</strong> {item.assigned}</p>
                </div>

              ))}
              </Row>
            <Button variant="primary" type='submit' onClick={handleSubmit}>Dodaj</Button>
          </Form>
          <WarehouseAddModal getAddedItems={setAddedItems} passChildData={setOpenModal} show={openModal} setModalData={setModalData} modalData={modalData} addedItems={addedItems}/>
      </div>
    </>
  );
}

export default withRouter(AddPumpHeat);
