import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import "./App.css"

export default function WarehouseModal(props) {
  //const [warehouseData, setWarehouseData] = useState([]);
  //const [warehouseOrderData, setWarehouseOrderData] = useState([]);
  const [warehouseOrderItemData, setWarehouseOrderItemData] = useState([]);
  const [test, setTest] = useState(true)

  const setShow = (data) => {
    props.passChildData(data);
  }

  const refresh = (firstArr, secondArr) => {
    props.getData(firstArr, secondArr)
  }

  const cleanup = () => {
    //setWarehouseOrderData([]);
    setWarehouseOrderItemData([]);
    //setWarehouseData([]);
    setShow(false);
  }

  const createOrderArray = (array) => {
    let tempArr = [];
    let length = array.length > props.data.length ? array.length : props.data.length
    for (var i = 0; i < length; i++) {
      if (props.data[i] !== undefined) {
        var orderItem = array.find(({ id }) => parseInt(id) === parseInt(props.data[i].equipement_id))
        if (orderItem !== undefined) {
          orderItem.assigned = parseInt(props.data[i].quantity)
          orderItem.item_id = parseInt(props.data[i].id)
          orderItem.purchase_price = parseInt(props.data[i].purchase_price)
          orderItem.selling_price = parseInt(props.data[i].selling_price)
          tempArr.push(orderItem);
        }
      }
    }

    for (var j = 0; j < length; j++) {
      if (array[j] !== undefined) {
        var orderItem = tempArr.find(({ id }) => parseInt(id) === parseInt(array[j].id))
        if (orderItem) {
          continue
        }
        let temp = []
        temp = array[j] !== undefined ? array[j] : props.data[j];
        temp.assigned = 0;
        tempArr.push(temp);
      }

    }

    setWarehouseOrderItemData(tempArr);
    setTest(!test)
  }

  const addItemToOrder = (item) => {
    var tempArr = warehouseOrderItemData;
    const index = tempArr.findIndex(({ id }) => parseInt(id) === parseInt(item.id));
    tempArr[index].assigned = tempArr[index].assigned + 1
    tempArr[index].quantity = parseInt(tempArr[index].quantity) - 1
    setWarehouseOrderItemData(tempArr)
    setTest(!test);
  }

  const deleteItemFromOrder = (item) => {
    var tempArr = warehouseOrderItemData;
    const index = tempArr.findIndex(({ id }) => id === item.id);
    if (tempArr[index].assigned > 0) {
      tempArr[index].assigned = tempArr[index].assigned - 1
      tempArr[index].quantity = parseInt(tempArr[index].quantity) + 1
    }
    setWarehouseOrderItemData(tempArr)
    setTest(!test)
  }

  const saveOrderWarehouse = () => {
    console.log(warehouseOrderItemData)
    let PUTjson = [];
    let POSTjson = [];
    for (var i = 0; i < warehouseOrderItemData.length; i++) {
      if (warehouseOrderItemData[i].item_id) {
        var tempJson2 = {
          "name": warehouseOrderItemData[i].name,
          "equipement_id": warehouseOrderItemData[i].id,
          "id": warehouseOrderItemData[i].item_id,
          "order_id": props.itemIndex,
          // "item_id": parseInt(warehouseOrderItemData[i].id),
          "quantity": parseInt(warehouseOrderItemData[i].assigned),
          "destination": warehouseOrderItemData[i].destination,
          "purchase_price": warehouseOrderItemData[i].purchase_price,
          "selling_price": warehouseOrderItemData[i].selling_price
        }
        PUTjson.push(tempJson2);
      } else if (warehouseOrderItemData[i].assigned > 0){
        var tempJson = {
          "order_id": props.itemIndex,
          "name": warehouseOrderItemData[i].name,
          "item_id": parseInt(warehouseOrderItemData[i].id),
          "quantity": parseInt(warehouseOrderItemData[i].assigned),
          "destination": warehouseOrderItemData[i].destination
        }
        POSTjson.push(tempJson);
      }


    }

    if(POSTjson.length > 0){
      axios
      .post(
        `https://fotodemo.srv22208.microhost.com.pl/warehouse/ordered_items.php`,
        POSTjson,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
        }
        }
      )
      .then((response) => {
        console.log(response)
      })
    }

      
      if(PUTjson.length > 0){
        console.log("DWA")
        axios
        .put(
          `https://fotodemo.srv22208.microhost.com.pl/warehouse/ordered_items.php`,
          PUTjson,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
          }
          }
        )
        .then((response) => {
          console.log(response)
        })
      }
   
    setShow(false);
    refresh(PUTjson, POSTjson);
  }


  useEffect(() => {
    // setWarehouseData(props.warehouseData);
    // setWarehouseOrderData(props.data);
    createOrderArray(props.warehouseData);
    setTest(!test)
  }, [props.show])

  const renderWarehouseElements = () => {
    return (
      warehouseOrderItemData.map((item, index) => {
        return (
          <div key={`modal-item-${index}`} className={`row ${index === 0 ? "border-modal-both" : "border-modal"}`}>
            <div className="col-sm row mx-0 mt-3">
              <p>Nazwa części: {item.name}</p>
              <p key={test} className="ml-4">Dostępne: {item.quantity}</p>
            </div>
            <div className="col-sm row">

              <Button className="mb-2 mt-2 d-flex justify-content-center align-items-center" variant="success" size='sm' onClick={() => addItemToOrder(item)}>Dodaj</Button>
              <p key={test} className="mx-1 mt-3">Przypisane: {item.assigned}</p>
              <Button className="mb-2 mt-2 d-flex justify-content-center align-items-center" variant="danger" size='sm' onClick={() => deleteItemFromOrder(item)}>Odejmij</Button>
            </div>
          </div>



        )
      })
    )
  }

  return (
    <Modal animation={false} scrollable={true} show={props.show} onHide={() => cleanup()} dialogClassName="w90" centered>
      <Modal.Header closeButton>
        <Modal.Title>{props.modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container overflow-y">
          <div className="row">
            <div className="col-sm">
              <h2>MAGAZYN</h2>
            </div>
            <div className="col-sm">
              <h2 >PRZYPISANE</h2>
            </div>
          </div>
          {renderWarehouseElements()}
          <Button className="mb-2 mt-2 d-flex justify-content-center align-items-center" variant="success" size='lg' onClick={() => saveOrderWarehouse()}>Zapisz</Button>

        </div>
      </Modal.Body>
    </Modal>

  );
};



